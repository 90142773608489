import Vue from 'vue'
import Router from "vue-router";
// import VueMeta from 'vue-meta';
import store from './store';

Vue.use(Router)
// Vue.use(VueMeta)



const router = new Router({
  mode: "history",
  // base: '/admin/',
  scrollBehavior (to, from, savedPosition) {
    to
    from 
    savedPosition
    return new Promise((resolve, reject) => {
      reject
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300)
    })
  },
  
    routes: [
    
      {
        path: '/auth',
        // name:'auth',
        component: () => import('./views/pages/Index.vue'),
        children: [{
          name: 'Login',
          path: '',
          component: () => import('./views/pages/Login.vue')
        }]
      },
      {
        path: '/',
        component: () => import('./views/dashboard/Index.vue'),
        beforeEnter: (to, from, next) => {
          
          if (localStorage.getItem('tokinn') && store.state.AdminInfo.authe==true) {
            next()
          } else {
            // next({
            //   name: 'Login'
            // });
            router.push({ path: '/auth' });
          }
        },   
        children: [
          {
            name: 'الاحصاءات',
            path: 'stats',
            component: () => import('./views/dashboard/chart.vue')
          },

     
          {
            path:'applicant',
            name:'المتقدمين على الوظيفة',
            component: () => import('./views/dashboard/applicant.vue'),
          }
,
         
          { path: '/arts', component: require('./components/ArtsAdmin.vue').default },
          { path: '/AffiliateSites', component: require('./components/AffiliateSites.vue').default },
    
          { path: '/videos', component: require('./components/videos.vue').default },
          { path: '/videos_types', component: require('./components/videos_types.vue').default },
          { path: '/books', component: require('./components/books.vue').default },
          { path: '/books_types', component: require('./components/books_types.vue').default },
          { path: '/books_types2', component: require('./components/books_types2.vue').default },
          { path: '/mags', component: require('./components/mags.vue').default },
          { path: '/mags_types', component: require('./components/mags_types.vue').default },
          { path: '/contactus', component: require('./components/contactus.vue').default },
          { path: '/divisions', component: require('./components/divisions.vue').default },
          { path: '/conferences_types', component: require('./components/conferences_types.vue').default },
          { path: '/conferences', component: require('./components/conferences.vue').default },
          { path: '/details', component: require('./components/details.vue').default },

          
          { path: '/about', component: require('./components/about_admin.vue').default },
          { path: '/about_stages', component: require('./components/about_stages.vue').default },

          { path: '/posts', component: require('./components/posts_admin.vue').default },
      
          { path: '/cats', component: require('./components/cat_admin.vue').default },
          { path: '/mails', component: require('./components/mails.vue').default },
          { path: '/writers', component: require('./components/writers.vue').default },
          { path: '/about_admin', component: require('./components/about_admin.vue').default }, 
          
        ]   
        
      },
    
    
    
       
       
    ]
    });
    
    
    
    
    router.beforeEach((to, from, next) => {
     
        window.document.title = to.name ;
   
      next();
    });
    
    export default router;

