<template>
  <div>


    <v-toolbar flat >
      <v-toolbar-title class="dodo">المراكز التابعة</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>


      <v-layout row wrap>
        <v-flex xs12 sm4>

          <!-- <v-select v-model="editedItem.cat_filter_id" :items="video_types" item-text="name" item-value="id"
            label="قسم الفيديو" required @change="cats_filter()" return-object persistent-hint
            v-on:input="cats_filter(`${editedItem.cat_filter_id.id}`)"></v-select> -->



        </v-flex>


     
      </v-layout>

      <v-spacer></v-spacer>

      


      <v-dialog v-model="dialog" persistent max-width="1300px" max-height="1300px">
        <v-btn slot="activator" color="primary" dark class="dodo mb-2" @click="dialog=true">اضافة </v-btn>
        <v-form v-model="valid" ref="form">
          <v-card>

            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.title" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="الاسم"></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-textarea v-model="editedItem.description" autofocus browser-autocomplete label="التفاصيل"
                      data-vv-name="text">
                    </v-textarea>
                  </v-flex>


                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.map" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="map"></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.fb" 
                      class="form_label" label="facebook"></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.twtr"
                      class="form_label" label="twitter"></v-text-field>
                  </v-flex>


                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.inst"
                      class="form_label" label="instagram"></v-text-field>
                  </v-flex>


                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.yt"
                      class="form_label" label="youtube"></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.link" class="form_label" label="الرابط"></v-text-field>
                  </v-flex>

                  <v-flex xs6>
                    <v-text-field label="اختر الصورة" @click='pickFile' v-model='imageName' prepend-icon='fa-solid fa-paperclip'></v-text-field>
                    <input type="file" style="display: none" ref="image" accept="image/*" @change="onFilePicked">
                  </v-flex>


                </v-layout>
                







              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click="close">الغاء</v-btn>
              <v-btn color="blue darken-1" :loading="load" flat @click="save">حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-toolbar>
    <v-data-table :headers="headers" :items="arts" class="elevation-1" :items-per-page="15" hide-default-footer
      disable-pagination>


      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>


      </template>


      <template v-slot:[`item.actions`]="{ item }">


        <v-icon small class="mr-2" @click="editItem(item)">
          تعديل
        </v-icon>
        |
        <v-icon small @click="deleteItem(item)">
          حذف
        </v-icon>



      </template>

      <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
        style="position: relative; top: 20px;" circle="" :length="pageCount">
      </v-pagination>

    </v-data-table>

    <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
      style="position: relative; top: 20px;" circle="" :length="pageCount">
    </v-pagination>


  </div>
</template>


<script>
  import swal from 'sweetalert2'

  import axios from 'axios'
  export default {

    props: [
      'settings'
    ],
    data() {
      return {
        data: new FormData(),
        errors: {},
        page: 1,
        pageCount: 0,
        current_page: 1,
        last_page: 0,
        percentCompleted: 0,

        nameRules: [
          (v) => !!v || 'يجب ادخال هذا الحقل',

        ],

        catsRules: [
          (v) => !!v || 'يجب ادخال عنوان المقاله',

        ],

        writersRules: [
          (v) => !!v || 'يجب ادخال اسم المكتبه',

        ],

        numRules: [
          (v) => !!v || 'يجب ادخال رقم العدد',

        ],

        ArtTypeRules: [
          (v) => !!v || 'يجب ادخال نوع المقاله',

        ],
        uploadPercentage: 0,
        form: new FormData,
        video_types:[],
        attachments: [],
        attached_images: [],
        form: new FormData,
        valid: false,
        dialog: false,
        imagesUrl: '',
        imageName: '',
        load: false,
        img_old: '',
        posts_status: [
          {
            id:1,
            name:'نشر',

          },
          {
            id:0,
            name:'في الانتظار',

          }
        ],
        writers: [],
        imagesName: ' ',
        search: null,
        filez: [],
        imageFile: [],
        imageUrl: '',
        journal_nums: [],
        imageFile: '',
        arts: [],
        view_ats: [],
        cats: [],
        dialog_mul: false,
        headers: [


          {
            text: 'ID',
            value: 'id',
            sortable: false,
          },
          {
            text: 'الاسم',
            align: 'left',
            value: 'title',
            align: "right",
            sortable: false,

          },

          // {
          //   text: 'رقم الهاتف',
          //   value: 'phone',
          //   sortable: false,
          // },
          // {
          //   text: 'العنوان',
          //   value: 'address',
          //   sortable: false,
          // },
          // {
          //   text: 'Facebook',
          //   value: 'facebook',
          //   sortable: false,
          // },
          // {
          //   text: 'Twitter',
          //   value: 'twitter',
          //   sortable: false,
          // },
          // {
          //   text: 'instagram',
          //   value: 'instagram',
          //   sortable: false,
          // },
          // {
          //   text: 'Telegram',
          //   value: 'telegram',
          //   sortable: false,
          // },
          {
            text: 'تاريخ الاضافة',
            value: 'created_at',
            sortable: false,
          },

          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            sortable: false
          }

        ],

        desserts: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          email: '',
          phone: '',
          picsid: '',
          password: '',
          state_id: '',
        },
        defaultItem: {
          name: '',
          email: '',
          phone: '',
          password: '',

        },
        def: {
          name: 'sds',


        }

      }
    },

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? 'أضافه مقاله جديده' : 'Edit Item'
      }
    },

    watch: {

      selected: 'search by sub_cat_id',

    },
    computed: {
      selected: function () {
        return this.getMoreitems();
      }
    },
    dialog(val) {
      val || this.close()
    },


    created() {
      this.initialize()
    },

    methods: {
      



      resetData() {
        this.editedItem = {};
        this.editedIndex = -1
      },
      deletepic(imgs) {


        this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: 'yes',
          cancelButtonText: 'no',
        }).then((result) => {
          // Send request to the server
          if (result.value) {
            axios.delete('attached_images/' + imgs.id, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              },
            }).then(() => {
              this.$swal.fire('Successfully', 'done', "success");
            }).catch(() => {
              swal("Failed!", "There was something wronge.", "warning");
            });
          }
        })


      },

      pickFile() {
        this.$refs.image.click()
      },


      pickFiles() {
        this.$refs.images.click()

      },


      onFilesPicked(e) {

        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        for (var i = files.length - 1; i >= 0; i--) {
          this.attachments.push(files[i]);
        }
        console.log(this.attachments);
        document.getElementById("attachments").value = [];

      },

      onFilePicked(e) {


        const files = e.target.files


        if (files[0] !== undefined) {



          this.imageName = files[0].name
          // console.log( this.imageName );

          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }


          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {

            this.imageUrl = fr.result
            this.imageFile = files[0]


          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''


        }
      },
      initialize() {

        axios.get('divisions?page=' + this.current_page, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })



          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      },



      editItem(item) {
        this.editedIndex = this.arts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem(item) {


        const index = this.desserts.indexOf(item)
        //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)


        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          // Send request to the server
          if (result.value) {
            axios.delete('divisions/' + item.id, {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              },
            }).then(() => {
              this.$swal.fire('Successfully', 'done', "success");
              this.initialize()
            }).catch(() => {
              swal("Failed!", "There was something wronge.", "warning");
            });
          }
        })



      },

      close() {
        this.dialog = false
        this.imageUrl = '';
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save() {
        this.load = true;
        let formData = new FormData();
        formData.append('title', this.editedItem.title);
        formData.append('description', this.editedItem.description);
        formData.append('fb', this.editedItem.fb);
        formData.append('twtr', this.editedItem.twtr);
        formData.append('inst', this.editedItem.inst);
        formData.append('yt', this.editedItem.yt);
        formData.append('link', this.editedItem.link);
        formData.append('map', this.editedItem.map);
        formData.append('image', this.imageFile);

        if (this.editedIndex > -1) {
          //edit here

          await axios.post('divisions/' + this.editedItem.id, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }).then(() => {
            this.dialog = false
            this.load = false;
            this.resetData();
            this.dialog = false;
            this.imageFile = '';
            this.imageName = '';
            this.$swal.fire('Successfully', 'done', "success");
          }).catch(() => {});
          this.initialize();
        } else {

          if (this.$refs.form.validate()) {
            //save New 
            await axios.post('divisions', formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            }).then((res) => {
              this.initialize();
              this.load = false;
              this.resetData();
              this.dialog = false;
              this.imageFile = '';
              this.imageName = '';
              this.$swal.fire('Successfully', 'done', "success");
            }).catch(() => {});

            this.initialize();
            this.close()

          }

        }
      }
    },



    mounted() {
      this.$validator.localize('en', this.dictionary)
      this.initialize();

    }

  }
</script>