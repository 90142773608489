<template>
  <div>


    <v-toolbar flat >
      <v-toolbar-title class="dodo">الاصدارات</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>


      <v-layout row wrap>
        <v-flex xs12 sm4>
<br>
<br>
          <v-select v-model="editedItem.cat_filter_id" :items="books_types" item-text="name" item-value="id"
            label="قسم الاصدارات" required @change="cats_filter();is_search=true;current_page=0" return-object persistent-hint
            v-on:input="cats_filter(`${editedItem.cat_filter_id.id}`)"></v-select>



        </v-flex>


     
      </v-layout>

      <v-spacer></v-spacer>

      <!--Uploude Mul File-->
      <v-dialog v-model="dialog_mul" max-width="700px">

        <v-card>
          <v-card-title>
            <span class="dodo">الصور المرفقه</span>
          </v-card-title>



          <v-container grid-list-md>
            <v-layout row wrap>
              <v-form v-model="valid" ref="formx">
                <input id="attachments" type="file" multiple="multiple" @change="uploadFieldChange">

                <progress max="100" :value.prop="uploadPercentage"></progress>
                <br>
                <br>
              </v-form>

            </v-layout>

            <v-layout row wrap>



              <v-flex v-for="imgs in attached_images" :key="imgs.id">
                <div style="width:80px;height:80px">

                  <img v-bind:src="Url+'img_art/thumb/'+imgs.img_name" style="width:100%;height:100%;">

                </div>

                <v-btn v-on:click="deletepic(imgs)">Deleted
                </v-btn>
              </v-flex>

            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="success" @click="close_mul" style="margin-left:15px;">Cancel</v-btn>
            <v-btn color="error" :loading="loade" @click="save_mul">Save</v-btn>

          </v-card-actions>

        </v-card>
      </v-dialog>
      <!--Uploude Mul File-->


      <v-dialog v-model="dialog" persistent max-width="1300px" max-height="1300px">
        <v-btn slot="activator" color="primary" dark class="dodo mb-2" @click="dialog=true">اضافة اصدار جديد</v-btn>
        <v-form v-model="valid" ref="form">
          <v-card>

            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.title" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="العنوان"></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.publisher" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="الناشر"></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.pub_num" v-validate="'required'" required :rules="nameRules" class="form_label" label="رقم الاصدار"></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-textarea v-model="editedItem.description" autofocus autocomplete label="التفاصيـــل"
                      data-vv-name="name">
                    </v-textarea>
                  </v-flex>


                  <v-flex xs6 sm6 md6>
                    <v-autocomplete v-model="editedItem.pub_cat_id" :items="books_types" item-text="name" item-value="id" label="قسم الاصدار" :rules="ArtTypeRules" hide-no-data>
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs6 sm6 md6>
                    <v-autocomplete v-model="editedItem.pub_cat2_id" :items="books_types2" item-text="name" item-value="id" label="قسم 2 للاصدار" :rules="ArtTypeRules" hide-no-data>
                    </v-autocomplete>
                  </v-flex>


                  <v-flex xs6>
                    <v-text-field label="اختر الصورة الرئيسية للاصدار" @click='pickFile' v-model='imageName'
                      prepend-icon='fa-solid fa-paperclip'></v-text-field>
                    <input type="file" style="display: none" ref="image" accept="image/*" @change="onFilePicked">
                  </v-flex>

                  <v-flex xs6>
                    <img :src="imageUrl" height="150" v-if="imageUrl" />
                  </v-flex>

                </v-layout>

                <v-col class="py-0 my-7" cols="6" sm="6" md="6" v-if="editedIndex == -1">
                  <v-text-field label="ارفق الاصدار " @click='pickFile2' v-model='file_name' id="file" prepend-icon='fas fa-paperclip'> </v-text-field>
                  <input type="file" style="display: none" ref="file" id="file" accept="application/pdf" v-on:change="onPickfile2">
                </v-col>


                <hr>
                <h3 class="text-center my-4" >الاجزاء</h3>
                <hr>
                <!-- v-if="editedIndex == -1" -->
                <v-col class="py-0" cols="12" >
                  <v-layout mt-3>
                    <template v-if="editedIndex != -1" v-for="file in editedItem.book_parts">
                          <v-flex px-2>
                            <div class="text-center">{{ file.part_title }}</div>
                            <v-layout justify-center>
                              <v-btn color="red" flat @click="delete_part(file.id)">مسح</v-btn>
                            </v-layout>
                          </v-flex>
                    </template>
                  </v-layout>
                  <div v-for="(file, index) in files" :key="index" style="margin-bottom: 10px;">
                    <v-layout mt-3>
                      <v-flex px-2>
                        <v-text-field  v-model="file.title" class="form_label" label="عنوان الجزء"></v-text-field>
                      </v-flex>
                      <v-flex pt-8>
                        <label class="mx-4">الاصدار</label>
                        <input type="file" accept="application/pdf" @change="handleFileChange($event, index)"/>
                      </v-flex>
                      
                    </v-layout>
                    
                  </div>
                  <v-btn color="green darken-1" flat @click="addFile">+</v-btn>
                </v-col>


              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click="close">الغاء</v-btn>
              <v-btn color="blue darken-1" flat @click="save">حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-toolbar>
    <v-data-table :headers="headers" :items="arts" class="elevation-1" :items-per-page="15" hide-default-footer
      disable-pagination>


      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>


      </template>


      <template v-slot:[`item.actions`]="{ item }">

        <!-- <v-icon small @click="mul_img(item)" class="mr-2">
          fas fa-images
        </v-icon>
        | -->

        <v-icon small class="mr-2" @click="editItem(item)">
          تعديل
        </v-icon>
        |
        <v-icon small @click="deleteItem(item)">
          حذف
        </v-icon>



      </template>

      <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
        style="position: relative; top: 20px;" circle="" :length="pageCount">
      </v-pagination>

    </v-data-table>

    <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
      style="position: relative; top: 20px;" circle="" :length="pageCount">
    </v-pagination>


  </div>
</template>


<script>
  import swal from 'sweetalert2'

  import axios from 'axios'
  export default {

    props: [
      'settings'
    ],
    data() {
      return {
        data: new FormData(),
        errors: {},
        page: 1,
        pageCount: 0,
        is_search:false,
        current_page: 1,
        last_page: 0,
        percentCompleted: 0,

        nameRules: [
          (v) => !!v || 'يجب ادخال عنوان الاصدار',

        ],

        activeRules: [
          (v) => !!v || 'يجب ادخال حالة الاصدار',

        ],

        ArtTypeRules: [
          (v) => !!v || 'يجب ادخال قسم الاصدار',

        ],
        uploadPercentage: 0,
        form: new FormData,
        books_types:[],
        books_types2:[],
        attachments: [],
        attached_images: [],
        form: new FormData,
        valid: false,
        dialog: false,
        imagesUrl: '',
        imageName: '',
        loade: false,
        img_old: '',
        file_name: null,
        posts_status: [
          {
            id:1,
            name:'نشر',

          },
          {
            id:0,
            name:'في الانتظار',

          }
        ],
        writers: [],
        imagesName: ' ',
        search: null,
        filez: [],
        imageFile: [],
        imageUrl: '',
        journal_nums: [],
        imageFile: '',
        arts: [],
        view_ats: [],
        cats: [],
        files: [{ title: '', file: null }],
        dialog_mul: false,
        headers: [


          {
            text: 'ID',
            value: 'id',
            sortable: false,
          },
          {
            text: 'ألعنوان',
            align: 'left',
            value: 'title',
            align: "right",
            sortable: false,

          },


          {
            text: 'قسم الاصدار',
            value: 'cat.name',
            sortable: false,
          },
          {
            text: 'تاريخ النشر',
            value: 'created_at',
            sortable: false,
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            sortable: false
          }

        ],

        desserts: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          email: '',
          phone: '',
          picsid: '',
          password: '',
          state_id: '',
        },
        defaultItem: {
          name: '',
          email: '',
          phone: '',
          password: '',

        },
        def: {
          name: 'sds',


        }

      }
    },

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? 'أضافه مقاله جديده' : 'Edit Item'
      }
    },

    watch: {

      selected: 'search by sub_cat_id',

    },
    computed: {
      selected: function () {
        return this.getMoreitems();
      }
    },
    dialog(val) {
      val || this.close()
    },


    created() {
      this.initialize()
      this.article_type()
      this.article_type2()
      Fire.$on('cats_fliter', () => {

        axios.get('books_sub_types_id/' + this.editedItem.cat_filter_id.id, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
        })
          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      });

      Fire.$on('viewAt_filter', () => {

        axios.get('art_by_viewat/' + this.editedItem.view_filter_id.id).then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      });


    },

    methods: {
      addFile() {
        this.files.push({ title: '', file: null });
      },
      handleFileChange(event, index) {
        const fileInput = event.target;
        if (fileInput.files.length > 0) {
          this.$set(this.files, index, {
            ...this.files[index],
            file: fileInput.files[0]
          });
        }
      },





      
      getMoreitems() {

        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          if(this.is_search){
          
          axios.get('books_sub_types_id/' + this.editedItem.cat_filter_id.id+'?page=' + this.current_page)
          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          }).then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })
          }
          else{
            this.initialize();
          }

        }
      
       
      },





      


      


      resetData() {
        this.editedItem = {};
        this.editedIndex = -1
      },
      start() {
      },


      

      pickFile() {
        this.$refs.image.click()
      },


      pickFiles() {
        this.$refs.images.click()

      },

      pickFile2() {
        this.$refs.file.click()
      },
      onPickfile2(e) {

        this.file = this.$refs.file.files[0];
        let file = e.target.files;
        this.file = file[0]
        this.file_name = file[0].name
      },

      onFilePicked(e) {
        const files = e.target.files
        if (files[0] !== undefined) {
          this.imageName = files[0].name
          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.imageUrl = fr.result
            this.imageFile = files[0]
          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
        }
      },



      cats_filter() {
        Fire.$emit('cats_fliter');
      },

      viewAt_filter() {
        Fire.$emit('viewAt_filter');
      },


      initialize() {

        axios.get('books?page='+this.current_page, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })



          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      },



      article_type() {
        axios.get('books_sub_list' , {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }).then(response => (this.books_types = response.data))
      },

      article_type2() {
        axios.get('books_sub_list2' , {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }).then(response => (this.books_types2 = response.data))
      },

      

      editItem(item) {
        this.editedIndex = this.arts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },



      delete_part(id) {

      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          axios.delete('book_part/' + id, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            },
          }).then(() => {
            this.$swal.fire('Successfully', 'done', "success");
            this.editedItem.book_parts = this.editedItem.book_parts.filter(item => item.id !== id);
          }).catch(() => {
            swal("Failed!", "There was something wronge.", "warning");
          });
        }
      })

      },

      deleteItem(item) {

        const index = this.desserts.indexOf(item)
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          // Send request to the server
          if (result.value) {
            axios.delete('books/' + item.id, {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              },
            }).then(() => {
              this.$swal.fire('Successfully', 'done', "success");
              this.initialize()
            }).catch(() => {
              swal("Failed!", "There was something wronge.", "warning");
            });
          }
        })

      },

      close() {
        this.dialog = false
        this.imageUrl = '';
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },


      save() {
        if (this.editedIndex > -1) {
          // edit here
          let formData = new FormData();
          formData.append('title', this.editedItem.title);
          formData.append('description', this.editedItem.description);
          formData.append('publisher', this.editedItem.publisher);
          formData.append('pub_num', this.editedItem.pub_num);
          formData.append('pub_cat_id', this.editedItem.pub_cat_id);
          formData.append('pub_cat2_id', this.editedItem.pub_cat2_id);
          formData.append('image', this.imageFile);
          formData.append('pdf', this.file);

          this.files.forEach(file => {
            if (file.file) {
              formData.append('files[]', file.file);
              formData.append('titles[]', file.title);
            }
          });

          axios.post('books/' + this.editedItem.id, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })
        .then(() => {
            this.initialize();
            this.resetData();
            this.dialog = false;
            this.file_name = '';
            this.file = '';
            this.imageFile = '';
            this.imageName = '';
            this.$swal.fire('Successfully', 'done', "success");
          }).catch(() => {});

        } else {

          if (this.$refs.form.validate()) {

            //save New 
            if (this.imageFile == '') {
              this.$swal({
                title: "يجب اختيار صوره",
                text: "",
                icon: "error",
                confirmButtonText: "اغلاق",
              });
              return;
            }
            // if (this.file == null) {
            //   this.$swal({
            //     title: " يجب اختيار ملف الاصدار",
            //     text: "",
            //     icon: "warning",
            //     showCancelButton: true,
            //     confirmButtonColor: "#3085d6",
            //     cancelButtonColor: "#d33",
            //   });
            //   return;
            // }

            let formData = new FormData();
            formData.append('title', this.editedItem.title);
            formData.append('description', this.editedItem.description);
            formData.append('publisher', this.editedItem.publisher);
            formData.append('pub_num', this.editedItem.pub_num);
            formData.append('pub_cat_id', this.editedItem.pub_cat_id);
            formData.append('pub_cat2_id', this.editedItem.pub_cat2_id);
            formData.append('image', this.imageFile);
            formData.append('pdf', this.file);

            this.files.forEach(file => {
              if (file.file) {
                formData.append('files[]', file.file);
                formData.append('titles[]', file.title);
              }
            });

            axios.post('books', formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            })
            .then((res) => {
                this.$swal.fire('Successfully', 'done', "success");
                this.initialize();
                this.resetData();
                this.dialog = false;
                this.file_name = '';
                this.file = '';
                this.imageFile = '';
                this.imageName = '';
            })







            this.initialize();
            this.close()



          }

        }
      },

     
    },



    mounted() {
      this.$validator.localize('en', this.dictionary)
      this.initialize();

    }

  }
</script>