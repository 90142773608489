<template>
  <div>


    <v-toolbar flat >
      <v-toolbar-title class="dodo">من نحن - المراحل</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>


      <v-layout row wrap>



     
      </v-layout>

      <v-spacer></v-spacer>

      

      
      <v-dialog v-model="dialog" persistent max-width="1300px" max-height="1300px">
        <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="primary" dark class="dodo mb-2" @click="dialog=true">اضافة</v-btn>
      </template>
        <v-form v-model="valid" ref="form">
          <v-card>

            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.title" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="العنوان"></v-text-field>
                  </v-flex>



                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.year" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="السنة"></v-text-field>
                  </v-flex>



                  <v-flex xs12 sm12 md12>
                    <v-textarea v-model="editedItem.description" autofocus autocomplete label="التفاصيـــل"
                      data-vv-name="name">
                    </v-textarea>
                  </v-flex>


                  <v-flex xs6>
                    <input type="file" multiple="multiple" id="attachments" @change="uploadFieldChange">
                  </v-flex>

                </v-layout>


              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1"  @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1"  @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    
    </v-toolbar>
    <v-data-table :headers="headers" :items="arts" class="elevation-1" :items-per-page="15" hide-default-footer
      disable-pagination>


      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>


      </template>

      
      <template v-slot:[`item.date`]="{ item }">
                    {{cropdate(item.created_at)}}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        

        <v-icon small class="mr-2" @click="editItem(item)">
          تعديل
        </v-icon>
        |
        <v-icon small @click="deleteItem(item)">
          حذف
        </v-icon>



      </template>

    </v-data-table>

    <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
      style="position: relative; top: 20px;" circle="" :length="pageCount">
    </v-pagination>


  </div>
</template>


<script>
  import swal from 'sweetalert2'
  import moment from "moment";
  import axios from 'axios'
  export default {

    props: [
      'settings'
    ],
    data() {
      return {
        data: new FormData(),
        errors: {},
        page: 1,
        pageCount: 0,
        current_page: 1,
        last_page: 0,
        percentCompleted: 0,

        nameRules: [
          (v) => !!v || 'يجب ادخال عنوان المقاله',

        ],

        catsRules: [
          (v) => !!v || 'يجب ادخال عنوان المقاله',

        ],

        writersRules: [
          (v) => !!v || 'يجب ادخال اسم المكتبه',

        ],

        numRules: [
          (v) => !!v || 'يجب ادخال رقم العدد',

        ],

        ArtTypeRules: [
          (v) => !!v || 'يجب ادخال نوع المقاله',

        ],
        uploadPercentage: 0,
        form: new FormData,
        article_types:[],
        attachments: [],
        attached_images: [],
        form: new FormData,
        valid: false,
        dialog: false,
        imagesUrl: '',
        imageName: '',
        is_search:false,
        loade: false,
        img_old: '',
        posts_status: [
          {
            id:1,
            name:'نشر',

          },
          {
            id:0,
            name:'في الانتظار',

          }
        ],
        writers: [],
        imagesName: ' ',
        search: null,
        filez: [],
        imageFile: [],
        imageUrl: '',
        journal_nums: [],
        imageFile: '',
        arts: [],
        view_ats: [],
        cats: [],
        date:'',
        dialog_mul: false,
        headers: [


          {
            text: 'ID',
            value: 'id',
            sortable: false,
          },
          {
            text: 'العنوان',
            align: 'left',
            value: 'title',
            align: "right",
            sortable: false,

          },

          {
            text: 'السنة',
            value: 'year',
            sortable: false,
          },

          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            sortable: false
          }

        ],

        desserts: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          email: '',
          phone: '',
          picsid: '',
          password: '',
          state_id: '',
        },
        defaultItem: {
          name: '',
          email: '',
          phone: '',
          password: '',

        },
        def: {
          name: 'sds',


        }

      }
    },

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? ' اضافة ' : ' تعديل '
      }
    },

    watch: {

      selected: 'search by sub_cat_id',

    },
    computed: {
      selected: function () {

        return this.getMoreitems();
      }
    },
    dialog(val) {
      val || this.close()
    },
    filters: {
      strippedContent: function(string) {
           return string.replace(/<\/?[^>]+>/ig, " "); 
    }
   
    },

    created() {
      this.initialize()
      this.start();
    },

    methods: {

      pickFile() {
        this.$refs.image.click()
      },


      pickFiles() {
        this.$refs.images.click()

      },

      onFilePicked(e) {
        const files = e.target.files
        if (files[0] !== undefined) {
          this.imageName = files[0].name
          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.imageUrl = fr.result
            this.imageFile = files[0]
          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
        }
      },

      cropdate(x){
        return  x.slice(0, 10);
      },
      close_mul() {

        this.uploadPercentage = 0,

          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          }, 300)

        this.dialog_mul = false
      },

      
      getMoreitems() {
        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          if(this.is_search){
          
            axios.get('art_catid/' + this.editedItem.cat_filter_id.id+'?page=' + this.current_page , {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })
          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
           
          })


          }
       else{
        this.initialize();
       }

        }
      },
      getAttachmentSize() {

        this.upload_size = 0;
        this.attachments.map((item) => {
          this.upload_size += parseInt(item.size);
        });
        this.upload_size = Number((this.upload_size).toFixed(1));
        this.$forceUpdate();
      },


      prepareFields() {

        if (this.attachments.length > 0) {
          for (var i = 0; i < this.attachments.length; i++) {
            let attachment = this.attachments[i];
            this.data.append('attachments[]', attachment);
          }

        }
      },


      removeAttachment(attachment) {

        this.attachments.splice(this.attachments.indexOf(attachment), 1);

        this.getAttachmentSize();
      },


      


      uploadFieldChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        
        for (var i = files.length - 1; i >= 0; i--) {
          this.attachments.push(files[i]);
        }
        document.getElementById("attachments").value = [];
      },


      resetData() {
        this.data = new FormData(); // Reset it completely
        this.attachments = [];
        this.editedItem = {};
        this.editedIndex = -1
      },
      start() {
        // console.log('Starting File Management Component');
      },



      initialize() {
        axios.get('about_stages', { 
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
        })
          .then(d => {
            this.arts = d.data;
          })


      },





      editItem(item) {



        this.editedIndex = this.arts.indexOf(item)
        item.created_at = moment(item.created_at).format("YYYY-MM-DD hh:mm")
        this.editedItem = Object.assign({}, item)
        

        this.dialog = true
      },

      deleteItem(item) {


        const index = this.desserts.indexOf(item)
        //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)


        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          // Send request to the server
          if (result.value) {
            axios.delete('stages_delete/' + item.id, {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            }).then(() => {
              this.$swal.fire('Successfully', 'done', "success");
              this.initialize()
            }).catch(() => {
              swal("Failed!", "There was something wronge.", "warning");
            });
          }
        })



      },

    close() {
      this.dialog = false
      this.imageUrl = '';
      this.resetData();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },





    save() {
      this.prepareFields();
      if (this.editedIndex > -1) {
        // edit here

        this.data.append('title', this.editedItem.title);
        this.data.append('year', this.editedItem.year);
        this.data.append('description', this.editedItem.description);

        axios.post('about_stages/' + this.editedItem.id, this.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
        })
      .then(() => {
          this.initialize();
          this.resetData();
          this.dialog = false;
          this.$swal.fire('Successfully', 'done', "success");
        }).catch(() => {});
      } else {
        if (this.$refs.form.validate()) {
          // save New Art Here
          const formData = new FormData();
          this.data.append('title', this.editedItem.title);
          this.data.append('year', this.editedItem.year);
          this.data.append('description', this.editedItem.description);

          axios.post('stages_store', this.data, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })
        .then((res) => {
          this.initialize();
          this.resetData();
          this.dialog = false;
          this.$swal.fire('Successfully', 'done', "success");
          }).catch(() => {});
        }
      }
    }
  },
    


    



    mounted() {
      this.initialize();
    }

  }
</script>