<template>
  <div>


    <v-toolbar flat >
      <v-toolbar-title class="dodo">المجلات</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>




      <v-spacer></v-spacer>



      <v-dialog v-model="dialog" persistent max-width="1300px" max-height="1300px">
        <v-btn slot="activator" color="primary" dark class="dodo mb-2" @click="dialog=true">اضافة اصدار جديد</v-btn>
        <v-form v-model="valid" ref="form">
          <v-card>

            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.title" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="العنوان"></v-text-field>
                  </v-flex>


                  
                  <v-flex xs6 sm6 md6>
                    <v-autocomplete v-model="editedItem.mag_cat_id" :items="mags_types" item-text="name" item-value="id" label="القسم" :rules="ArtTypeRules" hide-no-data>
                    </v-autocomplete>
                  </v-flex>



                  <v-flex xs6>
                    <v-text-field label="اختر الصورة الرئيسية للاصدار" @click='pickFile' v-model='imageName'
                      prepend-icon='fa-solid fa-paperclip'></v-text-field>
                    <input type="file" style="display: none" ref="image" accept="image/*" @change="onFilePicked">
                  </v-flex>

                  <v-flex xs6>
                    <img :src="imageUrl" height="150" v-if="imageUrl" />
                  </v-flex>



                  
                </v-layout>

                <v-col class="py-0" cols="6" sm="6" md="6">
                  <v-text-field label="ارفق الاصدار " @click='pickFile2' v-model='file_name' id="file"
                    prepend-icon='fas fa-paperclip'>
                  </v-text-field>
                  <input type="file" style="display: none" ref="file" id="file" accept="application/pdf" v-on:change="onPickfile2">
                </v-col>



              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click="close">الغاء</v-btn>
              <v-btn color="blue darken-1" flat @click="save">حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-toolbar>
    <v-data-table :headers="headers" :items="arts" class="elevation-1" :items-per-page="15" hide-default-footer
      disable-pagination>


      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>


      </template>


      <template v-slot:[`item.actions`]="{ item }">

        <v-icon small class="mr-2" @click="editItem(item)">
          تعديل
        </v-icon>
        |
        <v-icon small @click="deleteItem(item)">
          حذف
        </v-icon>



      </template>

      <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
        style="position: relative; top: 20px;" circle="" :length="pageCount">
      </v-pagination>

    </v-data-table>

    <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
      style="position: relative; top: 20px;" circle="" :length="pageCount">
    </v-pagination>


  </div>
</template>


<script>
  import swal from 'sweetalert2'

  import axios from 'axios'
  export default {

    props: [
      'settings'
    ],
    data() {
      return {
        data: new FormData(),
        errors: {},
        page: 1,
        pageCount: 0,
        is_search:false,
        current_page: 1,
        last_page: 0,
        percentCompleted: 0,

        nameRules: [
          (v) => !!v || 'يجب ادخال عنوان الاصدار',

        ],

        activeRules: [
          (v) => !!v || 'يجب ادخال حالة الاصدار',

        ],

        ArtTypeRules: [
          (v) => !!v || 'يجب ادخال قسم الاصدار',

        ],
        uploadPercentage: 0,
        form: new FormData,
        mags_types:[],
        attachments: [],
        attached_images: [],
        form: new FormData,
        valid: false,
        dialog: false,
        imagesUrl: '',
        imageName: '',
        loade: false,
        img_old: '',
        file_name: null,
        posts_status: [
          {
            id:1,
            name:'نشر',

          },
          {
            id:0,
            name:'في الانتظار',

          }
        ],
        writers: [],
        imagesName: ' ',
        search: null,
        filez: [],
        imageFile: [],
        imageUrl: '',
        journal_nums: [],
        imageFile: '',
        arts: [],
        view_ats: [],
        cats: [],
        dialog_mul: false,
        headers: [


          {
            text: 'ID',
            value: 'id',
            sortable: false,
          },
          {
            text: 'ألعنوان',
            align: 'left',
            value: 'title',
            align: "right",
            sortable: false,

          },


          {
            text: 'تاريخ النشر',
            value: 'created_at',
            sortable: false,
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            sortable: false
          }

        ],

        desserts: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          email: '',
          phone: '',
          picsid: '',
          password: '',
          state_id: '',
        },
        defaultItem: {
          name: '',
          email: '',
          phone: '',
          password: '',

        },
        def: {
          name: 'sds',


        }

      }
    },

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? ' اضافة ' : ' تعديل '
      }
    },

    watch: {

      selected: 'search by sub_cat_id',

    },
    computed: {
      selected: function () {
        return this.getMoreitems();
      }
    },
    dialog(val) {
      val || this.close()
    },


    created() {
      this.initialize()
      this.article_type()
    },

    methods: {
      close_mul() {

        this.uploadPercentage = 0,

          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          }, 300)

        this.dialog_mul = false
      },

      
      getMoreitems() {

        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          if(this.is_search){
          
            axios.get('books_sub_types_id/' + this.editedItem.cat_filter_id.id+'?page=' + this.current_page)
          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })
          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
           this.pageCount = d.data.last_page;

         

           
          })


          }
          else{
      
        this.initialize();
       }

          }
      
       
      },
      


      


      resetData() {
        this.data = new FormData(); // Reset it completely
        this.attachments = [];
      },
      pickFile() {
        this.$refs.image.click()
      },


      pickFiles() {
        this.$refs.images.click()

      },

      pickFile2() {
        this.$refs.file.click()
      },
      onPickfile2(e) {

        this.file = this.$refs.file.files[0];
        let file = e.target.files;
        this.file = file[0]
        this.file_name = file[0].name
      },
      onFilesPicked(e) {

        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        for (var i = files.length - 1; i >= 0; i--) {
          this.attachments.push(files[i]);
        }
        console.log(this.attachments);
        document.getElementById("attachments").value = [];

      },

      onFilePicked(e) {


        const files = e.target.files


        if (files[0] !== undefined) {



          this.imageName = files[0].name
          // console.log( this.imageName );

          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }


          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {

            this.imageUrl = fr.result
            this.imageFile = files[0]


          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''


        }
      },

      article_type() {
        axios.get('mag_sub_types_all' , {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }).then(response => (this.mags_types = response.data.data))
      },


      initialize() {

          axios.get('mags?page='+this.current_page, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })



          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      },







      // view_at() {
      //   axios.get('view_ats').then(response => (this.view_ats = response.data))
      // },



      editItem(item) {




        this.editedIndex = this.arts.indexOf(item)
        this.editedItem = Object.assign({}, item)

        this.dialog = true


      },

      deleteItem(item) {


        const index = this.desserts.indexOf(item)
        //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)


        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          // Send request to the server
          if (result.value) {
            axios.delete('mags/' + item.id, {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              },
            }).then(() => {
              this.$swal.fire('Successfully', 'done', "success");
              this.initialize()
            }).catch(() => {
              swal("Failed!", "There was something wronge.", "warning");
            });
          }
        })



      },

      close() {
        this.dialog = false
        this.imageUrl = '';
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save() {
        if (this.editedIndex > -1) {
          // edit here
          let formData = new FormData();
          formData.append('title', this.editedItem.title);
          formData.append('image', this.imageFile);
          formData.append('pdf', this.file);
          formData.append('mag_cat_id', this.editedItem.mag_cat_id);

          axios.post('mags/' + this.editedItem.id, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })
        .then(() => {
            this.initialize();
            this.dialog = false;
            this.file_name = '';
            this.file = '';
            this.imageFile = '';
            this.imageName = '';
            this.$swal.fire('Successfully', 'done', "success");
          }).catch(() => {});

        } else {

          if (this.$refs.form.validate()) {

            //save New 
            if (this.imageFile == '') {
              this.$swal({
                title: "يجب اختيار صوره",
                text: "",
                icon: "error",
                confirmButtonText: "اغلاق",
              });
              return;
            }
            if (this.file == null) {
              this.$swal({
                title: " يجب اختيار ملف الاصدار",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
              });
              return;
            }

            let formData = new FormData();
            formData.append('title', this.editedItem.title);
            formData.append('image', this.imageFile);
            formData.append('pdf', this.file);
            formData.append('mag_cat_id', this.editedItem.mag_cat_id);

            axios.post('mags', formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            })
            // axios({
            //     method: 'post',
            //     url: 'mags',
            //     headers: {
            //       "Content-Type": "multipart/form-data",
            //       Accept: "application/json",
            //       Authorization: "Bearer " + this.$store.state.AdminInfo.token
            //     },
            //     data: { formData },
            // })
            .then((res) => {
                this.$swal.fire('Successfully', 'done', "success");
                this.initialize();
                this.dialog = false;
                this.file_name = '';
                this.file = '';
                this.imageFile = '';
                this.imageName = '';
            })







            this.initialize();
            this.close()



          }

        }
      }
    },



    mounted() {
      this.initialize();

    }

  }
</script>