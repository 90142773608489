import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './i18n';
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import store from './store'
import axios from 'axios'
import VueAxios from "vue-axios";
import metaInfo from 'vue-meta'
import '@fortawesome/fontawesome-free/css/all.css'
Vue.use(metaInfo)
Vue.use(metaInfo, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})




Vue.use(VueAxios, axios);
Vue.config.silent = true
Vue.config.productionTip = false
axios.defaults.baseURL = 'https://turathadmin.alkafeel.net/back/api';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api';

import router from './router'
window.Fire =  new Vue();



Vue.use(VueRouter)
Vue.use(VueSweetalert2);

//Config
Vue.config.productionTip = false
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
});

Vue.filter('formatMon', function(value) {
  if (value) {
    return moment(String(value)).format('DD MMM  YYYY')
  }
});

Vue.filter('formatMonn', function(value) {
  if (value) {
    return moment(String(value)).format('MMMM DD , YYYY')
  }
});
  
Vue.mixin({
  data: function () {
    return {
      Url: 'https://turath.alkafeel.net/',
      resUrl: 'https://turathadmin.alkafeel.net/back/api',
      http: 'https://'
    }
  }
})
Vue.config.productionTip = false;
Vue.config.productionTip = false;
Vue.config.silent = true
Vue.config.productionTip = false


let authenticate = Promise.resolve({ role:store.state.role });

authenticate.then(user => {
  user
new Vue({
  el: '#app',
  router,
  
  store,
  i18n,
  axios,
  vuetify,
  render: h => h(App),
  mounted () {
  
  }
})
})