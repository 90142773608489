<template>
  <div>


    <v-toolbar flat >
      <v-toolbar-title class="dodo">المكتبة المرئية</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>


      <v-layout row wrap>
        
        <v-flex xs12 sm4>
          <br>
          <br>

          <v-select v-model="editedItem.cat_filter_id" :items="video_types" item-text="name" item-value="id"
            label="قسم الفيديو" required @change="cats_filter();is_search=true;current_page=0" return-object persistent-hint
            v-on:input="cats_filter(`${editedItem.cat_filter_id.id}`)"></v-select>



        </v-flex>


     
      </v-layout>

      <v-spacer></v-spacer>

      


      <v-dialog v-model="dialog" persistent max-width="1300px" max-height="1300px">
        <v-btn slot="activator" color="primary" dark class="dodo mb-2" @click="dialog=true">فيديو جديد</v-btn>
        <v-form v-model="valid" ref="form">
          <v-card>

            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.title" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="العنوان"></v-text-field>
                  </v-flex>


                  <v-flex xs6 sm6 md6>
                    <v-autocomplete v-model="editedItem.cat_id" :items="video_types" item-text="name" item-value="id" label="قسم الفيديو" :rules="ArtTypeRules" hide-no-data>
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-textarea v-model="editedItem.description" autofocus browser-autocomplete label="التفاصيـــل"
                      data-vv-name="name">
                    </v-textarea>
                  </v-flex>



                  <v-flex xs6>
                    <v-text-field label="اختر الصورة الرئيسية للفيديو" @click='pickFile' v-model='imageName'
                      prepend-icon='fa-solid fa-paperclip'></v-text-field>
                    <input type="file" style="display: none" ref="image" accept="image/*" @change="onFilePicked">
                  </v-flex>

                  <v-flex xs6>
                    <img :src="imageUrl" height="150" v-if="imageUrl" />
                  </v-flex>
                </v-layout>

                <v-col class="py-0" cols="6" sm="6" md="6" v-if="editedIndex == -1">
                  <v-text-field label="أرفاق الفيديو " @click='pickFile2' v-model='file_name' id="file"
                    prepend-icon='fas fa-paperclip'>
                  </v-text-field>

                  <input type="file" style="display: none" ref="file" id="file" accept=".mp4" v-on:change="onPickfile2">
                </v-col>

                <v-flex xs6 sm6 md6>
                  <v-text-field v-model="editedItem.url" class="form_label" label="رابط يوتيوب" ></v-text-field>
                </v-flex>

                <v-flex xs12>
                    <v-autocomplete multiple v-model="editedItem.article" :items="news" item-text="title" item-value="id" label="ربط مع المؤتمرات" rules="" hide-no-data>
                    </v-autocomplete>
                </v-flex>

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click="close">الغاء</v-btn>
              <v-btn color="blue darken-1" :loading="load" flat @click="save">حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-toolbar>
    <v-data-table :headers="headers" :items="arts" class="elevation-1" :items-per-page="15" hide-default-footer
      disable-pagination>


      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>


      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-icon small class="mr-2" @click="editItem(item)">
          تعديل
        </v-icon>
        |
        <v-icon small @click="deleteItem(item)">
          حذف
        </v-icon>



      </template>

      <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
        style="position: relative; top: 20px;" circle="" :length="pageCount">
      </v-pagination>

    </v-data-table>

    <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
      style="position: relative; top: 20px;" circle="" :length="pageCount">
    </v-pagination>


  </div>
</template>


<script>
  import swal from 'sweetalert2'

  import axios from 'axios'
  export default {

    props: [
      'settings'
    ],
    data() {
      return {
        data: new FormData(),
        errors: {},
        page: 1,
        pageCount: 0,
        current_page: 1,
        last_page: 0,
        percentCompleted: 0,

        nameRules: [
          (v) => !!v || 'يجب ادخال عنوان الفيديو',

        ],

        activeRules: [
          (v) => !!v || 'يجب ادخال حالة الفيديو',

        ],

        ArtTypeRules: [
          (v) => !!v || 'يجب ادخال قسم الفيديو',

        ],
        uploadPercentage: 0,
        form: new FormData,
        video_types:[],
        attachments: [],
        attached_images: [],
        form: new FormData,
        valid: false,
        dialog: false,
        imagesUrl: '',
        imageName: '',
        load: false,
        img_old: '',
        file_name: null,
        posts_status: [
          {
            id:1,
            name:'نشر',

          },
          {
            id:0,
            name:'في الانتظار',

          }
        ],
        writers: [],
        news: null,
        imagesName: ' ',
        search: null,
        filez: [],
        imageFile: [],
        imageUrl: '',
        journal_nums: [],
        imageFile: '',
        arts: [],
        view_ats: [],
        cats: [],
        dialog_mul: false,
        headers: [


          {
            text: 'ID',
            value: 'id',
            sortable: false,
          },
          {
            text: 'ألعنوان',
            align: 'left',
            value: 'title',
            align: "right",
            sortable: false,

          },
          
          {
            text: 'قسم الفيديو',
            value: 'cat.name',
            sortable: false,
          },
          {
            text: 'الرابط',
            value: 'url',
            sortable: false,
          },
          {
            text: 'تاريخ النشر',
            value: 'created_at',
            sortable: false,
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            sortable: false
          }

        ],

        desserts: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          email: '',
          phone: '',
          picsid: '',
          password: '',
          state_id: '',
          url: '',
          article: []
        },
        defaultItem: {
          name: '',
          email: '',
          phone: '',
          password: '',
          url: '',
        },
        def: {
          name: 'sds',


        }

      }
    },

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? 'أضافه مقاله جديده' : 'Edit Item'
      }
    },

    watch: {

      selected: 'search by sub_cat_id',

    },
    computed: {
      selected: function () {
        return this.getMoreitems();
      }
    },
    dialog(val) {
      val || this.close()
    },


    created() {
      this.initialize()
      this.article_type()
      this.conf()
      Fire.$on('cats_fliter', () => {

        axios.get('video_catid/' + this.editedItem.cat_filter_id.id, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
        })
          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      });

      Fire.$on('viewAt_filter', () => {

        axios.get('art_by_viewat/' + this.editedItem.view_filter_id.id).then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      });

    },

    methods: {
      
      getMoreitems() {
   


        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          if(this.is_search){
          
          
            axios.get('video_catid/' + this.editedItem.cat_filter_id.id+'?page=' + this.current_page, {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            })
          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
           this.pageCount = d.data.last_page;

         

           
          })


          }
       else{
        this.initialize();
       }

        }

      },
      

      uploadFieldChange(e) {


        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        for (var i = files.length - 1; i >= 0; i--) {
          this.attachments.push(files[i]);
        }

        // Reset the form to avoid copying these files multiple times into this.attachments
        document.getElementById("attachments").value = [];

        console.log(this.attachments);
      },


      resetData() {
        this.editedItem = {};
        this.editedIndex = -1
      },


      

      pickFile() {
        this.$refs.image.click()
      },


      pickFiles() {
        this.$refs.images.click()

      },

      pickFile2() {
        this.$refs.file.click()
      },
      onPickfile2(e) {

        this.file = this.$refs.file.files[0];
        let file = e.target.files;
        this.file = file[0]
        this.file_name = file[0].name
      },
      onFilesPicked(e) {

        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        for (var i = files.length - 1; i >= 0; i--) {
          this.attachments.push(files[i]);
        }
        console.log(this.attachments);
        document.getElementById("attachments").value = [];

      },

      onFilePicked(e) {


        const files = e.target.files


        if (files[0] !== undefined) {



          this.imageName = files[0].name
          // console.log( this.imageName );

          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }


          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {

            this.imageUrl = fr.result
            this.imageFile = files[0]


          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''


        }
      },



      cats_filter() {


        Fire.$emit('cats_fliter');
      },

      viewAt_filter() {
        console.log('view at here');
        Fire.$emit('viewAt_filter');
      },


      initialize() {

        axios.get('video?page=' + this.current_page, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
          })



          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      },



      article_type() {
        axios.get('video_types', {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
        }).then(response => (this.video_types = response.data))
      },

      conf() {
        axios.get('conferences', {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
        }).then(response => (this.news = response.data.data))
      },





      editItem(item) {
        this.editedIndex = this.arts.indexOf(item)
        this.editedItem = Object.assign({}, item)

        this.dialog = true
      },

      deleteItem(item) {


        const index = this.desserts.indexOf(item)
        //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)


        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          // Send request to the server
          if (result.value) {
            axios.delete('video/' + item.id, {
              headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          },
            }).then(() => {
              this.$swal.fire('Successfully', 'done', "success");
              this.initialize()
            }).catch(() => {
              swal("Failed!", "There was something wronge.", "warning");
            });
          }
        })



      },

      close() {
        this.dialog = false
        this.imageUrl = '';
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },


      async save() {
        this.load = true;
        if (this.editedIndex > -1) {
          // edit here
          let formData = new FormData();
          formData.append('title', this.editedItem.title);
          formData.append('description', this.editedItem.description);
          formData.append('cat_id', this.editedItem.cat_id);
          formData.append('url', this.editedItem.url);
          formData.append('article', this.editedItem.article);
          formData.append('image', this.imageFile);

          await axios.post('video/' + this.editedItem.id, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })
        .then(() => {
            this.initialize();
            this.load = false;
            this.resetData();
            this.dialog = false;
            this.file_name = '';
            this.file = '';
            this.imageFile = '';
            this.imageName = '';
            this.$swal.fire('Successfully', 'done', "success");
          }).catch(() => {});

        } else {

          if (this.$refs.form.validate()) {

            //save New 
            if (this.imageFile == '') {
              this.$swal({
                title: "يجب اختيار صوره",
                text: "",
                icon: "error",
                confirmButtonText: "اغلاق",
              });
              return;
            }
            // if (this.file == null) {
            //   this.$swal({
            //     title: " يجب اختيار ملف الاصدار",
            //     text: "",
            //     icon: "warning",
            //     showCancelButton: true,
            //     confirmButtonColor: "#3085d6",
            //     cancelButtonColor: "#d33",
            //   });
            //   return;
            // }

            let formData = new FormData();
            formData.append('title', this.editedItem.title);
            formData.append('description', this.editedItem.description);
            formData.append('cat_id', this.editedItem.cat_id);
            formData.append('url', this.editedItem.url);
            formData.append('image', this.imageFile);
            formData.append('article', this.editedItem.article);
            formData.append('video_file', this.file);

            await axios.post('video', formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            }).then((res) => {
                this.$swal.fire('Successfully', 'done', "success");
                this.initialize();
                this.load = false;
                this.resetData();
                this.dialog = false;
                this.file_name = '';
                this.file = '';
                this.imageFile = '';
                this.imageName = '';
            })
            this.initialize();
            this.close()
          }
        }
      },

      save2() {

        if (this.editedIndex > -1) {
          //edit here



          axios.put('videos/' + this.editedItem.id, {
            title: this.editedItem.title,
            active: this.editedItem.active,
            videos_types_id: this.editedItem.videos_types_id,  
            url: this.editedItem.url,     
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }).then(() => {

              if (this.imageFile != null) {

                let formData = new FormData();
                formData.append('photo', this.imageFile);
                axios.post('update_img/' + this.editedItem.id,
                  formData,



                  {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }
                )

                this.imageName = ''
                this.imageFile = ''
                this.imageUrl = ''
                this.initialize();
              }





              this.dialog = false;
              this.$swal.fire('Successfully', 'done', "success");


            }).catch(() => {});
          this.initialize();

        } else {

          if (this.$refs.form.validate()) {

            //save New Art Here
            if (this.imageFile == '') {
              this.$swal({
                title: "يجب اختيار صوره",
                text: "",
                icon: "error",
                confirmButtonText: "اغلاق",
              });
              return;
            }
            if (this.file == null) {
              this.$swal({
                title: " يجب اختيار الفديو",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
              });
              return;
            }
            axios({
                method: 'post',
                url: 'videos',
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                },
                data: {
                  title: this.editedItem.title,
                  active: this.editedItem.active,
                  videos_types_id: this.editedItem.videos_types_id,       
                  url: this.editedItem.url,
                },
              }).then((res) => {
                // save video image
                let formData = new FormData();
                formData.append('photo', this.imageFile);
                axios.post('video_uploude-img/'+res.data.id,
                  formData, {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  },
                ).then(() => {
                // save video file || mp4
                let formDataFile = new FormData();
                formDataFile.append('video', this.file);
                axios.post('uploadVideo/'+res.data.id,
                formDataFile, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  },
                ).then(() => {
                  this.$swal.fire('Successfully', 'done', "success");
                  this.dialog = false;
                  this.file_name = '';
                  this.file = '';
                  this.imageFile = '';
                  this.imageName = '';
                  //console.log('successful');
                }).catch(() => {});
                  //console.log('successful')
                }).catch(() => {});

                
                // Fire.$emit('AfterCreate');
                // toast({
                //   type: 'success',
                //   title: 'Signed in successfully'
                // })


              }).catch(() => {});





            this.initialize();
            this.close()



          }

        }
      }
    },



    mounted() {
      this.initialize();

    }

  }
</script>