<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title class="dodo">بريد الرسائل</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>

            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" persistent max-width="900px">

                <!--<v-btn slot="activator" color="primary" dark class="dodo mb-2" >مقاله جديده</v-btn>-->

                <v-form v-model="valid" ref="form">
                    <v-card>

                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout wrap>

                                    <v-flex xs12 sm6 md12>
                                        <p class="dodo ad_con_title">
                                            <span> اسم المرسل:</span>
                                            {{editedItem.name}}
                                        </p>
                                    </v-flex>




                                    <v-flex xs12 sm12 md12>
                                        <p class="dodo ad_con_title">
                                          <span> ايميل المرسل : </span>
                                          {{editedItem.email}}
                                        </p>
                                    </v-flex>


                                    <v-flex xs12 sm12 md12>
                                        <p class="dodo ad_con_title">
                                          <span>نص الرساله : </span>
                                          {{editedItem.message}}
                                        </p>
                                    </v-flex>

                                    <v-flex xs12 sm12 md12>
                                      <p>
                                         <a :href="'mailto:'+editedItem.email"> الرد على الرساله</a>
                                      </p>
                                    </v-flex>


                                </v-layout>


                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" flat @click="close">Cancel</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
        </v-toolbar>
        <v-data-table :headers="headers" :items="contactsus" :items-per-page="15" class="elevation-1" hide-default-footer
  disable-pagination>

            <template slot="items" slot-scope="props">
                <td>{{ props.item.id}}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.email }}</td>
                <td>{{ props.item.phone }}</td>
                <td>{{ props.item.message }}</td>




                <!-- <td class="justify-center layout px-0">
                    <v-icon small class="mr-2" @click="editItem(props.item)">
                        edit
                    </v-icon>
                    <v-icon small @click="deleteItem(props.item)">
                        delete
                    </v-icon>
                </td> -->
            </template>
            <template v-slot:[`item.actions`]="{ item }">

                <v-flex xs12 sm12 md12>
                                      <p>
                                         <a :href="'mailto:'+item.email"> الرد على الرساله</a>
                                      </p>
                                    </v-flex>


</template>
            <template slot="no-data">
                <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>
        </v-data-table>
        <v-pagination class="pagination" total-visible="20" v-model="page"
      color="#c7000b" style="position: relative; top: 20px;" circle=""
      :length="pageCount">
    </v-pagination>
    </div>
</template>


<script>
import axios from 'axios'
    export default {

        props: [
            'settings'
        ],
        data() {
            return {
                data: new FormData(),
                errors: {},
                percentCompleted: 0,
                page: 1,
        pageCount: 0,
        current_page: 1,
        last_page: 0,
                titleRules: [
                    (v) => !!v || 'يجب ادخال عنوان من نحن',

                ],

                detailsRules: [
                    (v) => !!v || 'يجب ادخال التفاصيل',

                ],


                form: new FormData,
                attachments: [],
                attached_images: [],
                form: new FormData,
                valid: false,
                dialog: false,
                imagesUrl: '',
                imageName: '',
                contactsus: [],
                img_old: '',
                posts_status: [],
                writers: [],
                imagesName: ' ',
                search: null,
                filez: [],
                imageFile: [],
                imageUrl: '',
                journal_nums: [],
                imageFile: '',

                view_ats: [],
                cats: [],
                headers: [


                    {
                        text: 'ID',
                        value: 'id',
                        sortable: false,
                    },
                    {
                        text: 'الاسم',
                        align: 'left',
                        value: 'name',
                        sortable: false,
                    },
                    {
                        text: 'الايميل',
                        value: 'email',
                        sortable: false,
                    },
                    {
                        text: 'الهاتف',
                        value: 'phone',
                        sortable: false,
                    },
                    {
                        text: 'نص الرسالة',
                        value: 'msg',
                        sortable: false,
                    },
                    {
                        text: 'Actions',
                        value: 'actions',
                        sortable: false
                    }

                ],

                desserts: [],
                editedIndex: -1,
                editedItem: {
                    name: '',
                    email: '',
                    phone: '',
                    picsid: '',
                    password: '',
                    state_id: '',
                },
                defaultItem: {
                    name: '',
                    email: '',
                    phone: '',
                    password: '',

                },
                def: {
                    name: 'sds',


                }

            }
        },

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'أضافه مقاله جديده' : 'Edit Item'
            },selected: function () {
        return this.getMoreitems();
      }
        },

        watch: {

            selected: 'search by sub_cat_id',

        },
        dialog(val) {
            val || this.close()
        },


        created() {

            this.initialize();


        },

        methods: {
            getMoreitems() {
        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          this.initialize();

        }
      },
            // initialize() {

            //     axios.get('contactus').then(response => (this.contactsus = response.data.data))

            // },


            initialize() {

                axios.get('contactus?page=' + this.current_page,{
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                }
                })



                .then(d => {
                    this.contactsus = d.data.data;
                    this.last_page = d.data.last_page;
                this.pageCount = d.data.last_page;
                })


                },



            deletepic(imgs) {


                swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        axios.delete('attached_images/' + imgs.id,  {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }).then(() => {
                            swal(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            this.initialize()
                        }).catch(() => {
                            swal("Failed!", "There was something wronge.", "warning");
                        });
                    }
                })


            },



            editItem(item) {




                this.editedIndex = this.contactsus.indexOf(item)
                this.editedItem = Object.assign({}, item)

                this.dialog = true

                axios.get('attached_images/' + this.editedItem.id).then(response => (this.attached_images = response
                    .data));


                if (this.imageUrl[0] == null) {

                    this.img_old = this.editedItem.main_img;
                    this.imageUrl = 'img_art/thumb/' + this.img_old;

                }
            },

            deleteItem(item) {


                const index = this.desserts.indexOf(item)
                //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)


                swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        axios.delete('contactus/' + item.id).then(() => {
                            swal(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            this.initialize()
                        }).catch(() => {
                            swal("Failed!", "There was something wronge.", "warning");
                        });
                    }
                })



            },

            close() {
                this.dialog = false
                this.imageUrl = '';
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                }, 300)
            },

            save() {


                if (this.editedIndex > -1) {


                    axios.put('contactsus/' + this.editedItem.id, {
                            title: this.editedItem.title,
                            details: this.editedItem.details,

                        })


                        .then(() => {


                            this.dialog = false
                            toast({
                                type: 'success',
                                title: 'Signed in successfully'
                            })


                        }).catch(() => {});
                    this.initialize();

                } else {

                    if (this.$refs.form.validate()) {

                        //save New Art Here
                        axios.post('about', {

                            title: this.editedItem.title,
                            details: this.editedItem.details,


                        }).then(() => {



                            this.dialog = false
                            Fire.$emit('AfterCreate');
                            toast({
                                type: 'success',
                                title: 'Signed in successfully'
                            })


                        }).catch(() => {});
                        this.initialize();
                        this.close()



                    }

                }
            }
        },



        mounted() {
            this.$validator.localize('en', this.dictionary)
            this.initialize();

        }

    }

</script>
